import dayjs from 'dayjs';

const apiUrl = 'https://statsapi.mlb.com/api/v1/';
const checkBtn = document.getElementById('check');
const ejected = document.getElementById('ejected');
const lastChecked = document.getElementById('lastChecked');
const today = document.getElementById('today');
const sportId = 1;
const teamId = 112;
const CACHE_KEY = 'gameData';

let initted = false;
let gameId = false;

const isDataExpired = () => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { expiration } = JSON.parse(cachedData);
    const expirationTime = new Date(expiration);
    const now = new Date();
    const tomorrow = new Date();
    tomorrow.setHours(4, 0, 0, 0);
    return now > expirationTime || now < tomorrow;
  }
  return true;
};

const saveToCache = (data) => {
  const expiration = new Date();
  expiration.setHours(23, 59, 59, 999);
  localStorage.setItem(CACHE_KEY, JSON.stringify({
    data,
    expiration: expiration.getTime(),
  }));
};

const checkForEjection = async (gamePk) => {
  if (initted) {
    checkBtn.classList.add('working');
  }
  try {
    if (gamePk) {
      const url = `${apiUrl}game/${gamePk}/content`;
      const response = await fetch(url);
      if (response.ok) {
        const gameContent = await response.json();
        checkBtn.classList.remove('working');
        if (JSON.stringify(gameContent).includes('ounsell ejec')) {
          ejected.textContent = 'Yes';
          const imgElement = document.createElement('img');
          imgElement.src = './haha.webp';
          ejected.appendChild(imgElement);
          checkBtn.remove();
        }
        lastChecked.textContent = `Last checked: ${dayjs().format('h:mm:ss a')}`;
        return false;
      }
      throw new Error(`Error: ${response.status}`);
    }
    return null;
  } catch (error) {
    console.error('Error:', error);
    lastChecked.textContent = 'Error checking game status.';
    checkBtn.classList.remove('working');
    return null;
  }
};

const getTodaysGame = async () => {
  today.textContent = `Today is ${dayjs().format('dddd, MMM D')}.`;

  if (!isDataExpired()) {
    const { data } = JSON.parse(localStorage.getItem(CACHE_KEY));
    gameId = data.dates[0].games[0].gamePk;
    initted = true;
    checkForEjection(gameId);
    return data.totalGames ? gameId : false;
  }

  const url = `${apiUrl}schedule/games/?sportId=${sportId}&teamId=${teamId}`;
  try {
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      saveToCache(data);
      gameId = data.dates[0].games[0].gamePk;
      initted = true;
      checkForEjection(gameId);
      return data.totalGames ? gameId : false;
    }
    throw new Error(`Error: ${response.status}`);
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

document.addEventListener('DOMContentLoaded', async () => {
  gameId = await getTodaysGame();
  if (checkBtn) {
    checkBtn.addEventListener('click', () => checkForEjection(gameId));
  }
});
